import { forwardRef, useEffect, useRef, useState } from "react"
import { connect } from 'react-redux';
import { useMediaQuery } from "react-responsive";
import { genericErrorHandler } from "../../util/GenericErrorHandler";
import { getEmisoras } from "../../net/Connector";
import { 
    changeNumeroTarjetaText,
    changeEmisoras,
    changeTipoText,
    titularTarjetaChanged,
    dniTarjetaChanged,
    tipoDniTarjetaChanged,
    resetDataOnEnter,
    actionGetUserById,
    actionUpdateCheckoutCuotas
} from "../../actions";
import axios from '../../actions/instanceActions';
import './SemiSimpleCreditCardConfigComponent.css'
import SelectorSearcher from "../../components/generalComponents/selectorSearcher/SelectorSearcher";
import CustomTextInput from "../../components/generalComponents/customTextInput/CustomTextInput";
import { formatCreditCard } from "./CreditCardUtils";

let isTabletOrMobile = false;
const SemiSimpleCreditCardConfigComponent = forwardRef((props: any, ref) => {
    isTabletOrMobile = useMediaQuery({ query: '(max-width: 37.5em)' })

    //INPUTS
    const [cuotas, setCuotas] = useState<number>(6);
    const [emisora, setEmisora] = useState<any>(null);
    const [numeroTarjeta, setNumeroTarjeta] = useState<string>("");
    const [titular, setTitular] = useState<string>("");
    const [tipoDocumento, setTipoDocumento] = useState<any>(null);
    const [nroDocumento, setNroDocumento] = useState<string>("");
    
    //LISTS
    const [cuotasList, setCuotasList] = useState<any>(null);
    const [emisorasList, setEmisorasList] = useState<any>(null);
    const [tipoDocumentoList, setTipoDocumentoList] = useState<any>(null);

    useEffect(() => {
        const ready = !isDisabled();
        props.onChangeStatus(ready);
    })

    useEffect(() => {
        props.resetDataOnEnter()
        getEmisorBanks();
        hardCodeLists();
    }, []);

    useEffect(() => {
        if(props.admin && props.userId) {
            actionGetUserById(props.userId, setTipoDocumento, props.tipoDniTarjetaChanged, setNroDocumento, props.dniTarjetaChanged)
        }
    }, [props.admin])

    console.log(props.admin)

    const hardCodeLists = () => {
        const tipoDocuListAux: any = [
            {
                descripcion: 'D.N.I.',
                codigo: 6
            },
            {
                descripcion: 'C.D.I.',
                codigo: 1
            },
            {
                descripcion: 'L.E.',
                codigo: 3
            },
            {
                descripcion: 'L.C.',
                codigo: 4
            },
            {
                descripcion: 'PASAPORTE',
                codigo: 5
            },
            {
                descripcion: 'C.U.I.T.',
                codigo: 8
            },
            {
                descripcion: 'C.U.I.L.',
                codigo: 9
            }
        ]
        setTipoDocumentoList(tipoDocuListAux);

        const cuotasListAux: any = [
            {
                descripcion: '1 Cuota',
                codigo: 1
            },
            {
                descripcion: '6 Cuotas',
                codigo: 6
            }
        ]
        setCuotasList(cuotasListAux);
    }

    const getEmisorBanks = async () => {
        try {
            const response = await axios.get(getEmisoras());
            setEmisorasList(response.data);
        } catch (error) {
            genericErrorHandler(error)
        }
    }


    const validateNumeroTarjeta = (numero: any) => {
        // Emisora todavia no seleccionada?
        if (!emisora) {
            return false;
        }
        if (emisora?.pattern?.replaceAll(" ", "")?.length !== numero?.length) {
            return false
        }

        return true;

    }

    const isDisabled = () => {
        return (!emisora || !validateNumeroTarjeta(numeroTarjeta) || titular === '' || !tipoDocumento || nroDocumento?.length !== 8)
    }

    const handleCuotasChangingCheckout = (cuotas: any) => {
        props.actionUpdateCheckoutCuotas(props.checkOutData?.id, cuotas, setCuotas)
    }

    return (
        <div className="semiSimpleCreditCardConfigComponent-container">
            <div className="semiSimpleCreditCardConfigComponent-container-optionWrapper">
                <p className="semiSimpleCreditCardConfigComponent-container-optionWrapper-title bold">Cuotas&nbsp;<ul>*</ul></p>
                <div className="semiSimpleCreditCardConfigComponent-container-optionWrapper-list">
                    {cuotasList?.map((a: any) => (
                        <div className="semiSimpleCreditCardConfigComponent-container-optionWrapper-list-item">
                            <div 
                                className="semiSimpleCreditCardConfigComponent-container-optionWrapper-list-item-circle"
                                style={{ border: '2px solid var(--secondary500)' }}
                                onClick={() => handleCuotasChangingCheckout(a.codigo)}
                            >
                                <div 
                                    className="semiSimpleCreditCardConfigComponent-container-optionWrapper-list-item-circle-inner"
                                    style={{ transform: a.codigo === cuotas ? `scale(${1})` : `scale(${0})` }}
                                />
                            </div>
                            <b className="semiSimpleCreditCardConfigComponent-container-optionWrapper-list-item-text regular large">{a.descripcion}</b>
                        </div>
                    ))}
                </div>
            </div>
            <div className="semiSimpleCreditCardConfigComponent-container-selectorWrapper" style={{ opacity: !emisorasList ? 0.5 : 1 }}>
                <p className="semiSimpleCreditCardConfigComponent-container-selectorWrapper-title bold">Seleccioná el emisor&nbsp;<ul>*</ul></p>
                <SelectorSearcher
                    widthList={isTabletOrMobile ? '87.1%' : '31.4%'}
                    id='Selector Emisor'
                    list={emisorasList}
                    onValueSelected={(a: any) => {
                        props.changeTipoText(a)
                        setEmisora(a)
                        props.changeNumeroTarjetaText('')
                        setNumeroTarjeta('')
                    }}
                    selectedItem={emisorasList && emisora ? emisorasList?.filter((a: any) => a.code == emisora?.code)[0]?.name : ''}
                    placeholder='Seleccionar'
                    deleteSelectedItem={() => {
                        props.changeTipoText(null)
                        setEmisora(null)
                        props.changeNumeroTarjetaText('')
                        setNumeroTarjeta('')
                    }}
                    disabled={!emisorasList}
                /> 
            </div>
            <CustomTextInput
                disabled={!emisora}
                label='Número de tarjeta'
                required
                changeText={(text: any) => {
                    props.changeNumeroTarjetaText(text?.replace(/[^\d+$]+/g, ''))
                    setNumeroTarjeta(text?.replace(/[^\d+$]+/g, ''))
                }}
                value={formatCreditCard(numeroTarjeta?.replaceAll(" ", ""), emisora?.pattern)}
                maxLength={emisora?.pattern?.length}
                success={validateNumeroTarjeta(numeroTarjeta)}
                returnKeyType='done'
                error={numeroTarjeta !== "" ? !validateNumeroTarjeta(numeroTarjeta) : false}
                errorMessage='Número de tarjeta inválida'
            />
            <CustomTextInput
                label='Titular'
                required
                changeText={(e: string) => {
                    props.titularTarjetaChanged(e?.toUpperCase())
                    setTitular(e?.toUpperCase())
                }}
                value={titular}
                success={titular !== ''}
                placeholder='Como figura en la tarjeta'
            />
            <div className="semiSimpleCreditCardConfigComponent-container-selectorWrapper" style={{ opacity: !tipoDocumentoList ? 0.5 : 1 }}>
                <p className="semiSimpleCreditCardConfigComponent-container-selectorWrapper-title bold">Tipo de documento&nbsp;<ul>*</ul></p>
                <SelectorSearcher
                    widthList={isTabletOrMobile ? '87.1%' : '31.4%'}
                    id='Selector Tipo de documento'
                    list={tipoDocumentoList}
                    onValueSelected={(a: any) => {
                        props.tipoDniTarjetaChanged(a)
                        setTipoDocumento(a)
                        props.dniTarjetaChanged('')
                        setNroDocumento('')
                    }}
                    selectedItem={tipoDocumentoList && tipoDocumento ? tipoDocumentoList?.filter((a: any) => a.codigo == tipoDocumento?.codigo)[0]?.descripcion : ''}
                    placeholder='Seleccionar'
                    deleteSelectedItem={() => {
                        props.tipoDniTarjetaChanged(null)
                        setTipoDocumento(null)
                        props.dniTarjetaChanged('')
                        setNroDocumento('')
                    }}
                    disabled={!tipoDocumentoList}
                /> 
            </div>
            <CustomTextInput
                label='Nro de documento'
                required
                changeText={(e: string) => {
                    props.dniTarjetaChanged(e.replace(/[^0-9]+/g, ''))
                    setNroDocumento(e.replace(/[^0-9]+/g, ''))
                }}
                value={nroDocumento}
                success={nroDocumento?.length === 8}
                placeholder='Sólo números sin puntos'
                error={nroDocumento?.length !== 8 && nroDocumento !== ''}
                maxLength={8}
            />
        </div>
    );

});

const mapStateToProps = (state) => {
    return {
        checkOutData: state.checkOut.checkOutData,
        userData: state.user.userData,
        bankTypes: state.checkOut.bankTypes
    }
};

export default connect(mapStateToProps, {
    changeNumeroTarjetaText,
    changeEmisoras,
    changeTipoText,
    titularTarjetaChanged,
    dniTarjetaChanged,
    tipoDniTarjetaChanged,
    resetDataOnEnter,
    actionUpdateCheckoutCuotas
}, null, { forwardRef: true })(SemiSimpleCreditCardConfigComponent);

